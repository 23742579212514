<template>
  <one-last-step-template @back="onBack" @continue="showNextStep" />
</template>

<script>
import OneLastStepTemplate from '@/modules/questionnaire/components/steps/common/medical-background/one-last-step/OneLastStepTemplate';
import { makeStep } from '@/modules/questionnaire/mixins';

export default {
  name: 'OneLastStep',
  components: { OneLastStepTemplate },
  mixins: [makeStep([])],
  methods: {
    onBack() {
      this.showPreviousStep();
    }
  }
};
</script>
